import React from "react";
import "../Styles/Sponsors.css";

const Sponsors = () => {
  return (
    <div className="sponsor-container">
      {/* <h1>Our Sponsors</h1>

      <div className="sponsor-body-container">
        <img src={require("../../../Images/Robotics test image.jpg")} alt="" />
        <p>{<LoremIpsum />}</p>
      </div> */}

      <h1>Coming Soon...</h1>
    </div>
  );
};

export default Sponsors;
